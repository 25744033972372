/* eslint-env browser */
(function() {
	'use strict';

	// script for td
	function toggleSection(e) {

		// prevent clicks on links/audio elements from toggling the section
		if (e.target && (e.target.nodeName == 'A' || e.target.nodeName == 'AUDIO')) {
			return true;
		}

		var section = this;
		var className = 'is-active';

		if (section.classList) {
			section.classList.toggle(className);
		} else {
			var classes = section.className.split(' ');
			var existingIndex = classes.indexOf(className);

			if (existingIndex >= 0)
				classes.splice(existingIndex, 1);
			else
				classes.push(className);

			section.className = classes.join(' ');
		}
	}

	// add fast click/touch handling on mobile
	window.addEventListener('load', function() {
		new FastClick(document.body);
	}, false);

	// bind toggles but debounce clicks
	var debouncedToggle = Cowboy.debounce(250, true, toggleSection);
	var toggles = document.getElementsByClassName('toggle');
	for (var i=0; i<toggles.length; i++){
		toggles[i].addEventListener('click', debouncedToggle, false);
	}

})();
